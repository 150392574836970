import { useStoryblok, StoryblokComponent } from "@storyblok/react";
import React, {} from "react";


function App() {

  let slug =
    window.location.pathname === "/"
      ? "home"
      : window.location.pathname.replace("/", "");

  const story = useStoryblok(slug, { version: "draft" });

  if (!story || !story.content) {
    return <>
      <div class={"loading-ani"}><object aria-label="Loading..." data="/assets/logo-light-outline.svg" width="100" height="100" type="image/svg+xml"></object></div>
    </>;
  }

  return <><StoryblokComponent blok={story.content} /></>;
}
export default App;
