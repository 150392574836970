import { renderRichText, storyblokEditable } from "@storyblok/react";
import styled from "styled-components";
import LazyLoad from 'react-lazy-load';
const Teaser = ({ blok }) => {
  const renderedRichText = renderRichText(blok.body);
  const renderedImageCpation = renderRichText(blok.image_caption);
  let size = '800x800';
  let hasImage = '';
  if(blok.img_style){
    size = blok.img_style;
  }
  let alignment = '';
  if (blok.alignment) {
    alignment = blok.alignment;
  }
  let textAlign = '';
  if (blok.alignment_affects_text) {
    textAlign = "align-text";
  }
  let image = '';
  let imgClasses = blok.rounded_img ? 'rounded':'';

  let adornmentClass = blok.adornment ? blok.adornment : '';

  const ImageCaption= () => {
    let caption = '';
    if (blok.image_caption) {
      caption = <Component.ImageCaption dangerouslySetInnerHTML={{ __html:renderedImageCpation}}></Component.ImageCaption>;
    }
    return caption;
  };

  if (blok.image.filename) {
    image = <Component.Image><LazyLoad><img class={imgClasses} alt={blok.image.alt} src={blok.image.filename+"/m/"+size+"/smart"} /></LazyLoad>{ImageCaption()}</Component.Image>;
    hasImage = "has-img";
  } else {
    alignment = "text-only";
  }
  return (
    <Component.Teaser {...storyblokEditable(blok)} key={blok._uid} data-test="teaser" className={alignment + " " + adornmentClass + " " + textAlign + " " + hasImage}>
      <div class={"column"}>
        {image}
        <div class={"text-wrapper"}>
          <h2>{blok.headline}</h2>
          <div class="teaser-body" dangerouslySetInnerHTML={{ __html:renderedRichText}} />
        </div>
      </div>
    </Component.Teaser>
  );
};

const Component = {
  Image: styled.div`
    margin-bottom: 1em;
    align-self: flex-start;
  `,
  ImageCaption: styled.div`
    margin-top: -0.5rem;
    text-align: center;
    background: #fff3db;
    border: 1px inset #fade33;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    line-height: 1rem;
    > p {
      margin: 0 0 0.25rem;
    }
  `,
  Teaser: styled.article`
    padding-top: 1rem;
    
    @media only screen and (min-width: 1024px) {
      .column {
        display: flex; 
      }
      &.has-img {
        .text-wrapper {
          max-width: 50%;
          margin-left: 2rem;
        }
      }
    }
    &.img-right .column {
      flex-direction: row-reverse;
      &.align-text .text-wrapper {
        text-align: right;
      }
      @media only screen and (min-width: 1024px) {
        .text-wrapper {
          margin-right: 2rem;
          margin-left: 0;
        }
      }
    }
  `,
};
export default Teaser;
