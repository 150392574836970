import React, { useState, useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from 'react-router-dom';

function Menu() {
  const [openDrawer, toggleDrawer] = useState(false);
  const drawerRef = useRef(null);

  useEffect(() => {
    /* Close the drawer when the user clicks outside of it */
    const closeDrawer = event => {
      if (drawerRef.current && drawerRef.current.contains(event.target)) {
        return;
      }
      toggleDrawer(false);
    };
    document.addEventListener("mousedown", closeDrawer);
    return () => document.removeEventListener("mousedown", closeDrawer);
  }, []);

  return (
      <Styles.Wrapper>
        <CSSReset />
        <Navbar.Wrapper>
          <Navbar.Logo>
            <a class="logo" title="Home" href={"/"}>
              <span>
                <object aria-label="Logo" data="/assets/logo-light-outline.svg" width="300" height="300" type="image/svg+xml"></object>
              </span>
            </a>
          </Navbar.Logo>
          <HamburgerButton.Wrapper onClick={() => toggleDrawer(true)}>
            <HamburgerButton.Lines />
          </HamburgerButton.Wrapper>
          <Navbar.Items ref={drawerRef} openDrawer={openDrawer}>
            <Navbar.Item><NavbarLink to={{ pathname: '/', slug: '/' }}>Home</NavbarLink></Navbar.Item>
            {/*<Navbar.Item><NavbarLink to={`/posts`}>Posts</NavbarLink></Navbar.Item>*/}
            {/*<Navbar.Item><NavbarLink to={`/about`}>About</NavbarLink></Navbar.Item>*/}
          </Navbar.Items>
        </Navbar.Wrapper>
      </Styles.Wrapper>
  );
}

const Styles = {
  Wrapper: styled.header`
    display: flex;
  `
};

const NavbarLink= styled(Link)`    
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `
const Navbar = {
  Wrapper: styled.nav`
    flex: 1;
    align-self: flex-start;
    padding: .5rem 2rem .5rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    font-size: 1.3rem;
   
    // 40em == 640px
    @media only screen and (max-width: 40em) {
      background-color: white;
      position: fixed;
      width: 100vw;
      top: 0;
      z-index: 900
    }
    a.logo {
      display: inline-block;
      position: relative;
      z-index: 1;
      span {
        display: inline-block;
      }
    }
    object {
      height: auto;
      width: 120px;
      position: relative;
      z-index: -1;
      @media only screen and (max-width: 40em) {
        width: 60px;
      }
    }
  `,
  Logo: styled.div`
    padding: 0.25rem;
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;

    @media only screen and (max-width: 40em) {
      position: fixed;
      right: 0;
      top: 0;
      height: 100%;
      flex-direction: column;
      background-color: white;
      padding: 1rem 2rem;
      transition: 0.2s ease-out;
      transform: ${({ openDrawer }) =>
      openDrawer ? `translateX(0)` : `translateX(100%)`};
    }
  `,
  Item: styled.li`
    padding: 0 1rem;
    cursor: pointer;

    @media only screen and (max-width: 40em) {
      padding: 1rem 0;
    }
  `
};

const HamburgerButton = {
  Wrapper: styled.button`
    height: 2rem;
    width: 2rem;
    position: relative;
    font-size: 12px;
    display: none;

    @media only screen and (max-width: 40em) {
      display: block;
    }

    /* Remove default button styles */
    border: none;
    background: transparent;
    outline: none;

    cursor: pointer;

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 150%;
      width: 150%;
      top: -25%;
      left: -25%;
    }
  `,
  Lines: styled.div`
    top: 50%;
    margin-top: -0.125em;

    &,
    &:after,
    &:before {
      /* Create lines */
      height: 2px;
      pointer-events: none;
      display: block;
      content: "";
      width: 100%;
      background-color: black;
      position: absolute;
    }

    &:after {
      /* Move bottom line below center line */
      top: -0.8rem;
    }

    &:before {
      /* Move top line on top of center line */
      top: 0.8rem;
    }
  `
};

const CSSReset = createGlobalStyle`
  *,
  *::before, 
  *::after {
    margin: 0; 
    padding: 0;
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;      
  }
`;

export default Menu;
