import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./css/web/index.min.css";
import App from "./App";
import Menu from "./Menu";
import { storyblokInit, apiPlugin } from "@storyblok/react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Page from "./components/Page";
import PostContentType from "./components/PostContentType";
import Teaser from "./components/Teaser";
import Grid from "./components/Grid";
import Feature from "./components/Feature";
import Image from "./components/Image";
import BlogListing from './components/BlogListing';
import eBirdChecklist from './components/EBirdChecklist';
import TagManager from '@sooro-io/react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-5PM9MV'
}

TagManager.initialize(tagManagerArgs)
// 'console' in window && console.log("                         .\\r\\n                    ._`-\\\\ )\\\\,`-.-.\\r\\n                   \\\\\\'\\\\` \\\\)\\\\ \\\\)\\\\ \\\\|.)\\r\\n                 \\\\`)  |\\\\)  )\\\\ .)\\\\ )\\\\|\\r\\n                 \\\\ \\\\)\\\\ |)\\\\  `   \\\\ .\\')\\/|\\r\\n                ``-.\\\\ \\\\    )\\\\ `  . ., \\'(\\r\\n                \\\\\\\\ -. `)\\\\``- ._ .)` |\\\\(,_\\r\\n                `__  \\'\\\\ `--  _\\\\`. `    (\\/\\r\\n                  `\\\\,\\\\       .\\\\\\\\        \\/\\r\\n                    \\'` )  (`-.\\\\\\\\       `\\r\\n                       \\/||\\\\    `.  * _*|\\r\\n                                 `-.( `\\\\\\r\\n                                     `. \\\\\\r\\n                                       `(c");

storyblokInit({
  accessToken: "JHQ4D5NMooxoc5IOhX4KfAtt",
  use: [apiPlugin],
  apiOptions: { region: 'us' },
  components: {
    page: Page,
    post: PostContentType,
    teaser: Teaser,
    grid: Grid,
    feature: Feature,
    image: Image,
    ebirdChecklist: eBirdChecklist,
  },
});
const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Menu />
      <Routes>
        <Route path="/posts" element={<BlogListing />} />
        <Route path={'/'} element={<App />} />
        <Route exact={false} path={'/posts/:slug'} element={<App />} />
        <Route exact={false} path={'/:slug'} element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
