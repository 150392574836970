// StoryblokService.js
import StoryblokClient from 'storyblok-js-client';

const storyblok = new StoryblokClient({
  accessToken: 'JHQ4D5NMooxoc5IOhX4KfAtt', // Replace with your Storyblok access token
  region: "us"
});

export const getBlogPosts = async () => {
  console.log("something");
  const response = await storyblok.get('cdn/stories', {
    starts_with: 'posts'
  });
  console.log(response.data.stories);
  return response.data.stories;
};
