import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const PostContentType = ({ blok }) => (
  <main {...storyblokEditable(blok)}>
    <div class={"column"}><h1>{blok.title}</h1></div>
    {blok.body
      ? blok.body.map((blok) => (
          <StoryblokComponent blok={blok} key={blok._uid} />
        ))
      : null}
  </main>
);
 
export default PostContentType;