import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const Page = ({ blok }) => {
  let headingClasses = blok.show_title ? 'column' : 'column visually-hidden';

  return <main {...storyblokEditable(blok)}>
    <div className={headingClasses}><h1>{blok.title}</h1></div>
    {blok.body
        ? blok.body.map((blok) => (
            <StoryblokComponent blok={blok} key={blok._uid}/>
        ))
        : null}
  </main>
}
 
export default Page;