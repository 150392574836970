// BlogListing.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import the Link component
import { getBlogPosts } from './StoryblokService';
import LazyLoad from 'react-lazy-load';

function BlogListing() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getBlogPosts().then((data) => {
      setPosts(data);
    });
  }, []);

  let size = '800x800';

  return (
      <main>
          <div class={"column"}>
              <h1>Blog Posts</h1>
              <div class={'blog-list'}>
                  {posts.map((post) => (
                      <div key={post.id}>
                          <Link to={`/posts/${post.slug}`}>
                              {post.content.image && (
                                  <LazyLoad><img alt={post.content.image.alt} src={post.content.image.filename+"/m/"+size+"/smart"} /></LazyLoad>
                              )}
                              <div class={'text-wrapper'}>
                              <h2>{post.name}</h2>
                              <p class={"summary"}>{post.content.summary}</p>
                              </div>
                          </Link> {/* Link to the post's slug */}
                      </div>
                  ))}
              </div>
          </div>
      </main>
  );
}

export default BlogListing;
