// EBirdChecklist.js
import React, {useState, useEffect} from 'react';
import {renderRichText, storyblokEditable} from "@storyblok/react";
import axios from 'axios';
import ebirdTaxa from "../data/ebird.json";
import styled from "styled-components";

function EBirdChecklist({blok}) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const renderedRichText = renderRichText(blok.description);

    const NestedItemDetails = ({itemId, count}) => {
        return (
            <Component.Bird><Component.Species><strong>{ebirdTaxa[itemId]["COMMON_NAME"]} :</strong></Component.Species><Component.Count>{count}</Component.Count></Component.Bird>
        );
    };

    const renderItems = () => {
        const items = [];
        for (let i = 0; i < data.obs.length; i++) {
            const item = data.obs[i];
            items.push(
                <NestedItemDetails itemId={item.speciesCode} count={item.howManyStr}/>
            );
        }
        return items;
    };

    useEffect(() => {
        let webApiUrl = `https://api.ebird.org/v2/product/checklist/view/` + blok.checklist_id;
        let tokenStr = '40tlbq06k5bg';

        async function fetchData() {
            try {
                const response = await axios.get(webApiUrl, {headers: {"X-eBirdApiToken": `${tokenStr}`}});
                setData(response.data);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        }

        fetchData();
    }, [blok.checklist_id]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!data) return null;

    return (
        <div className={"column"}>
            <Component.EBirdChecklist {...storyblokEditable(blok)} className="column">
                <Component.ContentWrapper>
                    <Component.Description dangerouslySetInnerHTML={{__html: renderedRichText}}/>
                    <Component.TableWrapper>
                        <Component.Heading> {blok.heading} </Component.Heading>
                        <Component.BirdList>
                            {renderItems()}
                        </Component.BirdList>
                    </Component.TableWrapper>
                </Component.ContentWrapper>
            </Component.EBirdChecklist>
        </div>);
}

const Component = {
    Description: styled.div`
        @media only screen and (min-width: 1024px) {
            padding: 0 6em 0 0;
        }
        @media only screen and (min-width: 800px) {
            padding: 0 3em 0 0;
        }
    `,
    ContentWrapper: styled.div`
        @media only screen and (min-width: 800px) {
            display: flex;
        }
    `,
    TableWrapper: styled.div`
        display: block;
        font-size: 0.8em;
        min-width: 320px;
        max-width: 100%;
        @media only screen and (max-width: 800px) {
            margin: 3em auto;
            min-width: auto;
        }
    `,
    Heading: styled.h2`
        color: #333;
        text-align: center;
    `,
    EBirdChecklist: styled.article`
        background: #efdfca;
        padding: 2em 1.2em;
        border-radius: 1em;
        margin: 1.8em 0em;
        @media only screen and (min-width: 800px) {
            padding: 2em 3em;
        }
    `, BirdList: styled.table`
        line-height: normal;
        font-family: Helvetica, arial, sans-serif;
        border: none;
        border-collapse: collapse;
        @media only screen and (max-width: 800px) {
            margin: 1em auto;
        }

        td {
            padding: 0.25em;
        }
    `, Bird: styled.tr`
        border-bottom: 1px solid #cdc;
    `, Species: styled.td`
        text-align: right;
    `, Count: styled.td`
        text-align: center;
    `,

};

export default EBirdChecklist;
