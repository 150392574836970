import { storyblokEditable } from "@storyblok/react";
import styled from "styled-components";

const Image = ({ blok }) => {
  let size = blok.sizing;
  return(
  <Component.ContentWrapper {...storyblokEditable(blok)} className="column image">
    <img alt={blok.image.alt} src={blok.image.filename+"/m/"+size+"/smart"} />
  </Component.ContentWrapper>
  );
}

const Component = {
  ContentWrapper: styled.div`
        @media only screen and (max-width: 800px) {
            padding:0;
        }
    `
}

export default Image;
